import * as React from 'react'
import type * as base from 'src/api/base'
import { errorsToArray } from 'src/helpers/fns'
import { useTranslatable } from 'src/hooks/locale/utils'
import ErrorIcon from 'src/imgs/error_icon.svg'
import NoContent from 'src/views/components/NoContent'
import { useErrorValue } from './Error'

export default function ErrorElement(): React.ReactElement | null {
  const t = useTranslatable()
  const error = useErrorValue() as base.CommonError
  const message =
    error.type === 'ErrorsObject'
      ? error.errors.general?.[0] != null
        ? error.errors?.general?.[0]
        : 'unknown Error'
      : error.message

  return (
    <div className="my-5 w-full" data-testid="errorElement">
      <NoContent
        image={ErrorIcon}
        header={error.type === 'ForbiddenError' ? t('error:not_have_permission') : t('error:an_error_occurred')}
        subHeader={message ?? ''}
        errors={error.type === 'ErrorsObject' ? errorsToArray(error.errors) : []}
      />
    </div>
  )
}
