import { type StateManagerProps } from 'node_modules/react-select/dist/declarations/src/stateManager'
import * as React from 'react'
import ReactSelect, { type GroupBase, type SelectInstance } from 'react-select'

import { useTranslatable } from 'src/hooks/locale/utils'
import { useTheme } from 'src/state/providers/Theme'

type BaseProps<
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = Omit<
  StateManagerProps<Option, IsMulti, Group> & React.RefAttributes<SelectInstance<Option, IsMulti, Group>>,
  'styles'
>

interface Props {
  readonly hasError?: boolean
  readonly controlStyles?: { [key: string]: string | number }
  readonly menuPortalStyles?: { [key: string]: string | number }
}

export default function Select<
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(props: Props & BaseProps<Option, IsMulti, Group>): React.ReactElement {
  const t = useTranslatable()
  const theme = useTheme()

  return (
    <ReactSelect
      {...props}
      noOptionsMessage={() => t('common:no_options')}
      placeholder={
        props.placeholder === undefined
          ? t('common:select')
          : typeof props.placeholder === 'string'
            ? props.placeholder
            : null
      }
      styles={{
        control: (base) =>
          ({
            ...base,
            borderColor: props.hasError === true ? '#e13834' : theme === 'dark' ? '#525252' : '#ced4da',
            background: theme === 'dark' ? '#323232' : '#ffffff',
            color: theme === 'dark' ? '#ffffff' : '#000000',
            height: 48,
            ...props.controlStyles,
          }) as typeof base,
        menu: (base) =>
          ({
            ...base,
            zIndex: 9999,
          }) as typeof base,
        option: (base, { isFocused }) =>
          ({
            ...base,
            color: theme === 'dark' ? '#ffffff' : '#212529',
            backgroundColor: isFocused
              ? theme === 'dark'
                ? '#222222'
                : '#e0e0e0'
              : theme === 'dark'
                ? '#32323'
                : '#ffffff',
          }) as typeof base,
        menuPortal: (base) =>
          ({
            ...base,
            zIndex: 2000,
            ...props.menuPortalStyles,
          }) as typeof base,
        menuList: (base) =>
          ({
            ...base,
            background: theme === 'dark' ? '#323232' : '#ffffff',
          }) as typeof base,
        singleValue: (base) =>
          ({
            ...base,
            color: theme === 'dark' ? '#ffffff' : '#212529',
            zIndex: 2,
          }) as typeof base,
        valueContainer: (base) =>
          ({
            ...base,
            color: 'green',
          }) as typeof base,
        input: (base) =>
          ({
            ...base,
            color: theme === 'dark' ? '#ffffff' : '#212529',
          }) as typeof base,
      }}
    />
  )
}
