import * as decoders from 'decoders'

import env from 'src/helpers/env'

export type JSONValue = null | string | number | boolean | JSONObject | JSONArray
export type JSONArray = readonly JSONValue[]
export interface JSONObject {
  readonly [key: string]: JSONValue
}
export function optionalNullableDecoder<T>(decoder: decoders.Decoder<T>): decoders.Decoder<T | undefined> {
  return decoders.optional(decoders.nullable(decoder)).transform((value) => value ?? void null)
}

export interface ErrorsObject {
  readonly type: 'ErrorsObject'
  readonly errors: {
    readonly [key: string]: readonly string[]
  }
}

export const ErrorsObjectDecoder: decoders.Decoder<ErrorsObject> = decoders.object({
  type: decoders.hardcoded('ErrorsObject' as const),
  errors: decoders.dict(decoders.array(decoders.string)),
})

export function DataDecoder<T>(decoder: decoders.Decoder<T>): decoders.Decoder<T> {
  return decoders.either(
    decoders.object({ data: decoder }).transform((obj) => obj.data),
    decoder
  ) as decoders.Decoder<T>
}

export function ErrorMessageDecoderByType<T>(
  type: T
): decoders.Decoder<{ readonly type: T; readonly message: string }> {
  return decoders
    .either(
      decoders.object({
        errors: decoders.object({
          general: decoders.nonEmptyArray(decoders.string),
        }),
      }),
      decoders.object({ message: decoders.string })
    )
    .transform((obj) => {
      return {
        type,
        message: 'message' in obj ? obj.message : obj.errors.general[0],
      }
    })
}

export interface ErrorMessage {
  readonly type: 'ErrorMessage'
  readonly message: string
}

export const ErrorMessageDecoder: decoders.Decoder<ErrorMessage> = ErrorMessageDecoderByType('ErrorMessage' as const)

export interface AuthError {
  readonly type: 'AuthError'
  readonly message: string
}

export const AuthErrorDecoder: decoders.Decoder<AuthError> = ErrorMessageDecoderByType('AuthError' as const)

export interface MaintenanceMode {
  readonly type: 'MaintenanceMode'
  readonly message: string
}

export const MaintenanceModeDecoder: decoders.Decoder<MaintenanceMode> = ErrorMessageDecoderByType(
  'MaintenanceMode' as const
)
export interface ForbiddenError {
  readonly type: 'ForbiddenError'
  readonly message: string
}

export const ForbiddenErrorDecoder: decoders.Decoder<ForbiddenError> = ErrorMessageDecoderByType(
  'ForbiddenError' as const
)

export function ArrayObjectDecoder<T>(decoder: decoders.Decoder<T>): decoders.Decoder<T[]> {
  return decoders.either(
    decoders.array(decoder),
    decoders.mapping(decoder).transform((arr) => {
      const result: T[] = []

      for (const [k, v] of arr) {
        // @ts-expect-error ts issue
        result[k] = v
      }

      return result.filter((v) => v !== undefined)
    })
  )
}

export type CommonError = AuthError | ForbiddenError | ErrorsObject | MaintenanceMode | ErrorMessage

export const baseURL: string = env.SERVICE_URL

export interface Translatable {
  readonly ka: string
  readonly en: string
}
