import classnames from 'classnames'
import * as React from 'react'
import { useSearchParams } from 'react-router-dom'
import { useWindowSize } from 'react-use'
import type * as Api from 'src/api'
import { ArrowLeft } from 'src/assets/icons/customIcons/ArrowLeft'
import { ArrowRight } from 'src/assets/icons/customIcons/ArrowRight'
import { pagination } from 'src/helpers/fns'
import { useTranslatable } from 'src/hooks/locale/utils'
import Select from './Select'

interface Props {
  readonly pagination: Api.Pagination
}

export function PaginationTop(props: Props): React.ReactElement | null {
  return (
    <div
      className="my-3 flex flex-wrap justify-between sm:justify-end xxs:mt-2 xxs:justify-end xs:mt-2 xs:justify-end"
      data-testid="pagination-top"
    >
      <PerPageInput pagination={props.pagination} />

      <Pager pagination={props.pagination} />
    </div>
  )
}

export function PaginationBottom(props: Props): React.ReactElement | null {
  return (
    <div
      className="my-3 flex flex-wrap justify-between sm:justify-end xxs:mt-2 xxs:justify-end xs:mt-2 xs:justify-end"
      data-testid="pagination-bottom"
    >
      <CurrentPageInfo pagination={props.pagination} />

      <Pager pagination={props.pagination} />
    </div>
  )
}

interface PageItemProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  readonly pagination: Api.Pagination
  readonly page: number
  readonly children: React.ReactNode
  readonly className?: string
}

function PageItem({ page, pagination, className, children, ...pageItemProps }: PageItemProps): JSX.Element {
  const [, setSearchParams] = useSearchParams()
  const disabled = page > pagination.totalPages || page < 1

  return (
    <li className="ml-1 rounded !border !border-borderGrey hover:bg-veryLightGrey hover:text-white">
      <button
        className={classnames('flex size-9 items-center justify-center', className, {
          '!cursor-not-allowed': disabled,
        })}
        onClick={() =>
          setSearchParams((params) => {
            params.set('page', page.toString())

            return params
          })
        }
        disabled={disabled}
        {...pageItemProps}
      >
        {children}
      </button>
    </li>
  )
}

function CurrentPageInfo(props: Props): React.ReactElement {
  const t = useTranslatable()

  return (
    <div className="flex items-center justify-between" data-testid="records-total">
      <span className="text-primaryTextColor">
        {t('common:showing')} {props.pagination.currentPage * props.pagination.perPage - props.pagination.perPage + 1}{' '}
        {t('common:to')} {Math.min(props.pagination.currentPage * props.pagination.perPage, props.pagination.total)}{' '}
        {t('common:of')} {props.pagination.total} {t('common:entries')}
      </span>
    </div>
  )
}

const perPageOptions = [
  { value: 10, label: 10 },
  { value: 20, label: 20 },
  { value: 50, label: 50 },
]

function PerPageInput(props: Props): React.ReactElement {
  const t = useTranslatable()
  const [searchParams, setSearchParams] = useSearchParams({
    perPage: props.pagination.perPage.toString(),
  })
  const perPage = parseInt(searchParams.get('perPage')!)

  return (
    <div className="flex items-center justify-between" data-testid="perPageInputWrapper">
      <Select<{ value: number; label: number }>
        options={perPageOptions}
        controlStyles={{ height: 36 }}
        value={perPageOptions.find((option) => option.value === perPage)}
        defaultValue={perPageOptions.find((option) => option.value === 10)}
        onChange={(option) => {
          setSearchParams((params) => {
            params.set('perPage', option!.value.toString())
            params.delete('page')

            return params
          })
        }}
      />

      <span className="ml-2 text-primaryTextColor">{t('common:entries_per_page')}</span>
    </div>
  )
}

function Pager(props: Props): React.ReactElement {
  const [, setSearchParams] = useSearchParams()
  const { width } = useWindowSize()
  const pager = pagination(props.pagination.currentPage, props.pagination.totalPages, width)

  return (
    <ul className="flex text-primaryTextColor">
      <PageItem data-testid="previousButton" page={props.pagination.currentPage - 1} pagination={props.pagination}>
        <ArrowLeft />
      </PageItem>

      {pager.map((page) => {
        if (page.kind === 'dots') {
          return (
            <li
              key={page.page}
              className="ml-1 flex size-9 items-center justify-center rounded !border !border-borderGrey"
            >
              <a>...</a>
            </li>
          )
        } else {
          return (
            <li
              key={page.page}
              className={classnames('ml-1 rounded !border !border-borderGrey', {
                active: page.kind === 'currentPage',
              })}
            >
              <button
                className="size-9 rounded hover:bg-veryLightGrey hover:text-white"
                onClick={() =>
                  setSearchParams((params) => {
                    params.set('page', page.page.toString())

                    return params
                  })
                }
                data-testid={`page-${page.page}`}
              >
                {page.page}
              </button>
            </li>
          )
        }
      })}

      <PageItem page={props.pagination.currentPage + 1} data-testid="nextButton" pagination={props.pagination}>
        <ArrowRight />
      </PageItem>
    </ul>
  )
}
