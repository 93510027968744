import classnames from 'classnames'
import React from 'react'

export const Table = React.forwardRef<HTMLTableElement, JSX.IntrinsicElements['table']>(function Table(
  { children, className, ...props },
  ref
) {
  return (
    <div className={classnames('w-full overflow-x-scroll text-primaryTextColor', className)}>
      <table
        className={classnames('w-full min-w-[768px] rounded-card bg-card text-primaryTextColor', className)}
        ref={ref}
        {...props}
      >
        {children}
      </table>
    </div>
  )
})

export const Thead = React.forwardRef<HTMLTableSectionElement, JSX.IntrinsicElements['thead']>(function Thead(
  { children, ...props },
  ref
) {
  return (
    <thead {...props} ref={ref}>
      {children}
    </thead>
  )
})
export const Tbody = React.forwardRef<HTMLTableSectionElement, JSX.IntrinsicElements['tbody']>(function Tbody(
  { children, ...props },
  ref
) {
  return (
    <tbody {...props} ref={ref}>
      {children}
    </tbody>
  )
})
export const Tfoot = React.forwardRef<HTMLTableSectionElement, JSX.IntrinsicElements['tfoot']>(function Tfoot(
  { children, ...props },
  ref
) {
  return (
    <tfoot {...props} ref={ref}>
      {children}
    </tfoot>
  )
})
export const Tr = React.forwardRef<HTMLTableRowElement, JSX.IntrinsicElements['tr']>(function Tr(
  { children, ...props },
  ref
) {
  return (
    <tr {...props} ref={ref}>
      {children}
    </tr>
  )
})

export const Th = React.forwardRef<HTMLTableCellElement, JSX.IntrinsicElements['th']>(function Th(
  { children, className, ...props },
  ref
) {
  return (
    <th className={classnames('px-3 py-[20px] text-start text-primaryTextColor', className)} {...props} ref={ref}>
      {children}
    </th>
  )
})

export const Td = React.forwardRef<HTMLTableCellElement, JSX.IntrinsicElements['td']>(function Td(
  { children, className, ...props },
  ref
) {
  return (
    <td
      className={classnames('text-m whitespace-normal px-3 py-[20px] align-middle', className)}
      style={{ color: 'auto' }}
      {...props}
      ref={ref}
    >
      {children}
    </td>
  )
})
