import * as React from 'react'
import { unstable_usePrompt, useLocation } from 'react-router-dom'
import { useBeforeUnload, useMount } from 'react-use'
import env from 'src/helpers/env'
import { useTranslatable } from 'src/hooks/locale/utils'
import useSWR, { type Fetcher, type SWRConfiguration, type SWRResponse } from 'swr'

export interface APIResponse<Data, Error> extends SWRResponse<Data, Error> {
  readonly data: Data
}
interface FetchingConfig {
  readonly shouldFetch?: boolean | null | undefined
  readonly suspense?: boolean | null | undefined
}

interface UseApiConfig<Data, P, E extends (p: P) => Promise<Data>> {
  readonly endpoint: E
  readonly params: P
  readonly shouldFetch?: boolean | null | undefined
}

export function useApi<P, Data, C extends FetchingConfig, Error>(
  config: C & UseApiConfig<Data, P, (p: P) => Promise<Data>> & SWRConfiguration<Data, Error, Fetcher<Data, unknown[]>>
): APIResponse<
  C extends
    | {
        readonly shouldFetch: boolean | null | undefined
      }
    | {
        readonly suspense: boolean | null | undefined
      }
    ? Data | null
    : Data,
  Error
> {
  async function fn(): Promise<Data> {
    return await config.endpoint(config.params)
  }

  return useSWR((config.shouldFetch ?? true) ? [config.endpoint, config.params] : null, fn, config) as APIResponse<
    C extends
      | {
          readonly shouldFetch: boolean | null | undefined
        }
      | {
          readonly suspense: boolean | null | undefined
        }
      ? Data | null
      : Data,
    Error
  >
}

function useOutsideClick(elRef: React.RefObject<Element>, fn: () => unknown): void {
  const fnRef = React.useRef(fn)

  fnRef.current = fn

  useMount(() => {
    function handleClickOutside(e: MouseEvent): void {
      if (e.target == null) return void null
      if (elRef.current == null) return void null
      if (elRef.current.contains(e.target as Node)) return void null

      fnRef.current()
    }

    document.addEventListener('mouseup', handleClickOutside)

    return () => void document.removeEventListener('mouseup', handleClickOutside)
  })
}

export interface DropdownProps<T extends Element> {
  readonly show: boolean
  readonly setShow: (arg0: boolean) => void
  readonly ref: React.RefObject<T>
  readonly id: string
}

export function useDropdown<T extends Element>(hideOnOutsideClick: boolean = true): DropdownProps<T> {
  const [show, setShow] = React.useState<boolean>(false)
  const ref = React.useRef<T>(null)
  const id = `label-${React.useId()}`

  useOutsideClick(ref, () => {
    if (hideOnOutsideClick) {
      setShow(false)
    }
  })

  return {
    show,
    setShow,
    ref,
    id,
  }
}

export function useConsoleWarning(): void {
  const t = useTranslatable()

  React.useEffect(() => {
    if (env.APP_ENV === 'PRODUCTION') {
      const warningTitleCSS = 'color:red; font-size:60px; font-weight: bold; -webkit-text-stroke: 1px black;'
      const warningDescCSS = 'font-size: 18px;'
      // eslint-disable-next-line no-restricted-properties
      console.log(`%c${t('error:stop')}`, warningTitleCSS)
      // eslint-disable-next-line no-restricted-properties
      console.log(`%c${t('error:browser_warning')}`, warningDescCSS)
    }
  }, [t])
}

export function useFormikUnload({
  when,
  message = 'You have unsaved changes, are you sure?',
}: {
  when: boolean
  message?: string
}): void {
  useBeforeUnload(when, message)
  unstable_usePrompt({ when, message })
}

export function useScrollReset(ref: React.RefObject<HTMLDivElement>): void {
  const { pathname } = useLocation()
  React.useEffect(() => {
    ref.current?.scrollIntoView({
      block: 'end',
      inline: 'end',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])
}
